@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);
@import url("https://fonts.googleapis.com/css2?family=Piedra&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700&display=swap');
/*  #412394 */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  background-color: black;
  color: #FFF;
}



